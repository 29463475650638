<template>
  <div class="alertDialog">
    <v-dialog v-model="show" :width="alertWidth" persistent>
      <v-card>
        <v-card-title>
          <v-icon>{{ icon }}</v-icon>
          {{ alertTitle }}
        </v-card-title>
        <v-card-text align="center" justify="center">
          {{ alertMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <span v-for="(myBtn, i) in actionButtons" :key="i">
            <v-btn :color="myBtn.color" text @click="$emit(myBtn.emit)">
              {{ myBtn.text }}
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, required: true },
    alertTitle: { type: String, default: "Alert!" },
    alertMessage: { type: String, required: true },
    actionButtons: { type: Array, required: true },
    alertWidth: { type: String, default: "430" },
    icon: { type: String, default: "mdi-alert-octagon-outline" },
  },
};
</script>

<style lang="scss" scoped></style>
