import Vue from "vue";
import { Loader } from "google-maps";
import { getEnv } from "@/utils/util";

const googleMaps = {
  // eslint-disable-next-line no-shadow
  async install(Vue) {
    const loader = new Loader(getEnv("VUE_APP_GOOGLE_MAPS_KEY"), {
      libraries: ["places"],
    });
    const google = await loader.load();

    Vue.$google = google;
    Vue.prototype.$google = google;
  },
};

Vue.use(googleMaps);
